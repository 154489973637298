<template lang="pug">
div
  Header(:documentStatus="{ status: documentData?.status, time: documentData?.time_check, verification_number: documentData?.verification_number }")
  div.mt-20
    div.document-content-title.mb-5 {{ $t('documentInfo.certificate') }} № {{ documentData?.number_document }}
    div.document-content-table-content.mb-5 {{ $t('documentInfo.issueComment') }}
    div.document-content-table-content {{ $t('documentInfo.certifyThat') }}
    div.document-content-title.mt-25 {{ documentData?.[`name_${ langFields }`] }}
  div.d-flex.flex-column.mt-25
    div(v-if="documentData?.date_birth").document-content-column-title {{ $t('documentInfo.dateOfBirth') }}
      div.fw-500.black--text.align-self-end.text-right {{ documentData?.date_birth }}
    div.document-content-column-title {{ $t('documentInfo.successfullyCompletedCourse') }}
      div.fw-500.black--text.align-self-end.text-right {{ $t('documentInfo.maritimeAdministrationUkraine') }}
    div(v-if="documentData?.sti").document-content-column-title {{ $t('documentInfo.nameOfEducationalInstitution') }}
      div.fw-500.black--text.align-self-end.text-right {{ documentData?.sti?.[langFields] }}
    div(v-if="documentData?.course?.[langFields]").document-content-title.mt-20.mb-20 {{ documentData?.course?.[langFields] }}
    div(v-if="documentData?.course_date_end").document-content-column-title {{ $t('documentInfo.endDateOfCourse') }}
      div.fw-500.black--text.align-self-end.text-right {{ documentData?.course_date_end }}
    div(v-if="documentData?.date_start").document-content-column-title {{ $t('documentInfo.dateOfIssueDocument') }}
      div.fw-500.black--text.align-self-end.text-right {{ documentData?.date_start }}
    div(v-if="documentData?.date_end").document-content-column-title {{ $t('documentInfo.validUntil') }}
      div.fw-500.black--text.align-self-end.text-right {{ documentData?.date_end }}
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'EtiCertificate',
  props: { documentData: { type: Object, default: () => ({}) } },
  components: { Header: () => import('@/components/layouts/DocumentHeader') },
  computed: { ...mapState({ langFields: state => (state.app.lang === 'en') ? 'eng' : 'ukr' }) }
}
</script>